// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bitso-js": () => import("./../../../src/pages/bitso.js" /* webpackChunkName: "component---src-pages-bitso-js" */),
  "component---src-pages-construye-js": () => import("./../../../src/pages/construye.js" /* webpackChunkName: "component---src-pages-construye-js" */),
  "component---src-pages-cripto-js": () => import("./../../../src/pages/cripto.js" /* webpackChunkName: "component---src-pages-cripto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invierte-js": () => import("./../../../src/pages/invierte.js" /* webpackChunkName: "component---src-pages-invierte-js" */),
  "component---src-pages-remodela-js": () => import("./../../../src/pages/remodela.js" /* webpackChunkName: "component---src-pages-remodela-js" */)
}

