// gets called at the end of a bunch of events
export function debounce(func, delay) {
  // console.log(func);
  let later
  return () => {
    if (later) clearTimeout(later)
    later = setTimeout(() => {
      later = null
      // console.log('working?')
      func()
    }, delay)
  }
}

export const setHJson = (i, method, b) => {
  i = {
    method,
    mode: "cors",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `JWT ${
        sessionStorage.getItem(process.env.REACT_APP_TOKEN_NAME) || ""
      }`,
    }),
  }
  if (b) {
    i.body = JSON.stringify(b)
  }
  return i
}

export const setHFData = (i, method, b) => {
  i = {
    method,
    mode: "cors",
    headers: new Headers({
      Accept: "application/json, */*",
      // "Authorization":`JWT ${sessionStorage.getItem('ses_tok')}`
    }),
  }
  if (b) i.body = b
  return i
}

export const fetchStatus = response => {
  if (!response.ok) {
    if (process.env.NODE_ENV === "production") {
      // captureException(response.statusText);
    }
  }
  return response.json() // or return response.text()
}

export const getErrors = error => {
  // captureException(error);
  if (process.env.NODE_ENV === "production") {
    // captureException(error);
  }
  return { error }
}

export const formatNmxn = value => {
  return parseFloat(value)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

// 2,500.00
export const numberFromFormatted = value => {
  return parseInt(value.replace(/,/g, ""), 10)
}

export const b = value => {
  return value
    .replace(/[^0-9,]/g, "")
    .replace(/,/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
